<template>
  <section class="">
    <div class="position-relative">
      <img
        class="w-100 img-fluid"
        src="@/assets/img/banner-modelos-y-programas-desktop.webp"
        alt="Imagen de inicio de seccion de modelos y programas"
        loading="lazy"
      />
      <div class="DivBannerEjecutivo">
        <div class="DivBannerEjecutivoTexto">
      <div class="form wet-form">
      <h2>
        <span style="color: #14993c"> Español </span>
      </h2>
        <input type="text" class="form-control form-titulo" v-model="titleES"/>
        <input type="text" class="form-control form-control-sm form-subtitulo" v-model="subtitleES"/>
        <button class="btn btn-success btn-lg mt-3" @click="syncContent('ES')">Guardar</button>
      </div>
        </div>
      </div>
    </div>
    <hr class="pill mb-3">
    <div class="position-relative">
      <img
        class="w-100 img-fluid"
        src="@/assets/img/banner-modelos-y-programas-desktop.webp"
        alt="Imagen de inicio de seccion de modelos y programas"
        loading="lazy"
      />
      <div class="DivBannerEjecutivo">
        <div class="DivBannerEjecutivoTexto">
      <div class="form wet-form">
      <h2>
        <span style="color: #14993c"> English </span>
      </h2>
        <input type="text" class="form-control form-titulo" v-model="titleEN"/>
        <input type="text" class="form-control form-control-sm form-subtitulo" v-model="subtitleEN"/>
        <button class="btn btn-success btn-lg mt-3" @click="syncContent('EN')">Save</button>
      </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getSection, putSection } from "../../helpers/contenido"
import store from '../../store';

export default {
  name: "ModeloBanner",
  data() {
    return {
        titleES: 'Modelo y Programas',
        titleEN: 'Modelo y Programas',
        subtitleES: 'Te ayudamos a cumplir tus desafíos y construir tu empresa con nosotros.',
        subtitleEN: 'Te ayudamos a cumplir tus desafíos y construir tu empresa con nosotros.'
    };

  },

  methods: {


    fetchData() {

       if (store.state.sections.challenges) {
      this.data = {...store.state.sections.modelBanner.components}; }
    },

    async fetchContent() {
      const section = await getSection("modelBanner");
      const sectionEs = section.filter( (section) => section.language == "ES"  )
      const sectionEn = section.filter( (section) => section.language == "EN"  )
      this.sectionEs = sectionEs
      this.sectionEn = sectionEn
      this.titleES = sectionEs[0].components.title
      this.subtitleES = sectionEs[0].components.subtitle
      this.titleEN = sectionEn[0].components.title
      this.subtitleEN = sectionEn[0].components.subtitle
    },

    async syncContent(lan) {
      if (lan === "ES") {
        const words = {
            id: this.sectionEs[0]._id,
            section: "modelBanner",
            components: {
                title: this.titleES,
                subtitle: this.subtitleES,
            },
            language: "ES"
        }
        await putSection(words);
      } else {
        const words = {
            id: this.sectionEn[0]._id,
            section: "modelBanner",
            components: {
                title: this.titleEN,
                subtitle: this.subtitleEN,
            },
            language: "EN"
        }
        await putSection(words);
      }
    }

  },

  watch: {
    '$store.state.sections': function () {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
    this.fetchContent();
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */

hr.pill {
  height:0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}

.ModeloBanner {
  position: relative;
}

.DivBannerEjecutivo {
  position: absolute; /* posición absolute con respecto al padre */
  top: 13%;
  width: 40%;
  height: 59%;
  left: 5%;
  /*background-color: #575da6;*/
  /*padding: 1%;*/
}

.DivBannerEjecutivoTexto {
  padding: 1%;
  margin-top: 15%;
}

.models-image {
  margin-left: -1rem;
}
.form-subtitulo{
  color: #14993c;
}
</style>
